import Swiper from 'swiper';

$(document).ready(() => {
	if($('.js-announceSlider').length){
		const announceSlider = new Swiper('.js-announceSlider .swiper-container', {
			observer: true,
			observeParents: true,
			speed: 600,
			spaceBetween: 30,
			slidesPerView: 1,
			navigation: {
				nextEl: '.js-announceSliderNext',
				prevEl: '.js-announceSliderPrev'
			},
			on: {
				slideChange(){
					const { activeIndex } = this;

					$('.js-announceSliderCurrent').text(activeIndex + 1);
				}
			}
		});
	}

	$('.js-showAllDesc').on('click', (event) => {
		$(event.currentTarget).parent().hide();
		$('[data-text-full]').slideDown(200);
		$('[data-text-small]').hide();
	});

	$('[data-fancybox]').fancybox({
		infobar: false,
	    buttons: ['close'],
		hideScrollbar: false,
		wheel: false,
		beforeShow(){
			$('body').addClass('is-burger-hide');
		},
		afterClose(){
			$('body').removeClass('is-burger-hide');
		}
	});

	$(document).on('keydown', (e) => {
		if (e.keyCode == 27) {
			$.fancybox.close();
		}
	});

	$(window).on('load resize', () => {
		overlayTitle();
	});

	overlayTitle();

	function overlayTitle(){
		const h = $('.detail-title__inner').height() + 50;

		$('.detail-header__overline').height(h);
	}

	function titleSize(){
		const title = $('.detail-title');

		if (title.text().length > 35) {
			title.addClass('is_small_size');
		} else {
			title.removeClass('is_small_size');
		}
	}

	titleSize();

	$(window).on('load resize', () => {
		titleSize();
	});
});
