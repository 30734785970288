import Cookies from 'js-cookie'

const $cardViewToggle = $('.js-cardViewToggle');
const $cardList = $('.card-list');
const $body = $('body');

$cardViewToggle.on('click', (event) => {
	const $this = $(event.currentTarget);

	$body.addClass('is_card_view_change');

	setTimeout(() => {
		$body.removeClass('is_card_view_change');

		$cardList.removeClass('card_list_view card_tile_view');

		if ($this.hasClass('is_tile_view')) {
			Cookies.set('TILE_VIEW', 'N', { expires: 7 });
			$this.removeClass('is_tile_view');
			$cardList.addClass('card_list_view');
		} else {
			Cookies.set('TILE_VIEW', 'Y', { expires: 7 });
			$this.addClass('is_tile_view');
			$cardList.addClass('card_tile_view');
			cardsAosShow();
		}
	}, 600);
});

function cardsAosShow(){
	$('[data-aos]').each((index, element) => {
		if ($(element).is(':mcsInSight')) {
		  $(element).addClass('aos-animate');
		}
	});
}

function checkCardsView(){
	const cookie = Cookies.get('TILE_VIEW');

	if (cookie && cookie === 'Y') {
		$cardViewToggle.addClass('is_tile_view');
		$cardList
			.addClass('card_tile_view')
			.removeClass('card_list_view');
	}
}

export function cardTitleFs(){
	$('.card-box__title').each((index, element) => {
		if ($(element).text().length > 40) {
			$(element).addClass('is_adaptive_font_small');
		} else if ($(element).text().length > 20) {
			$(element).addClass('is_adaptive_font');
		}
	});
}

$(document).on('cardTitleFontSize', () => {
	cardTitleFs();
});

checkCardsView();
cardTitleFs();
