function sectionDescSizeText(){
	const desc = $('.section-desc');

	if (desc.text().length > 300) {
		desc.addClass('is_small_size');
	} else {
		desc.removeClass('is_small_size');
	}
}

sectionDescSizeText();

$(window).on('load resize', () => {
	sectionDescSizeText();
});
