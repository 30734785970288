import Swiper from 'swiper';
const $body = $('body');

$('.main-nav')
	.on('mouseenter', (event) => {
		$body.addClass('is_main_nav_hover');
	})
	.on('mouseleave', (event) => {
		$body.removeClass('is_main_nav_hover');
	});

$(document).ready(() => {
	const allVideos = $('.js-mainPhotosSlider .swiper-slide video');

	if($('.js-mainPhotosSlider').length){
		const announceSlider = new Swiper('.js-mainPhotosSlider .swiper-container', {
			observer: true,
			observeParents: true,
			effect: 'fade',
			speed: 400,
			spaceBetween: 0,
			slidesPerView: 1,
			autoplay: {
				delay: 2000,
			},
			on: {
				slideChange(){
					const { activeIndex } = this;
					const currentSlide = $('.js-mainPhotosSlider .swiper-slide').eq(activeIndex);
					const currentVideo = currentSlide.find('video');

					allVideos.each((index, element) => {
						$(element)[0].pause();
					});

					if (currentVideo[0].paused) {
						currentVideo[0].play();
					}
				}
			}
		});

		$('.js-mainNavHover').on('mouseenter', (event) => {
			const index = $(event.currentTarget).parent().index();

			announceSlider.slideTo(index);
			announceSlider.autoplay.stop();
		});

		$('.js-mainNavHover').on('mouseleave', (event) => {
			announceSlider.autoplay.start();
		});
	}
});
