import { getTagIds } from './_filter-cards';
import { cardTitleFs } from './_cards';

const $cardListAjax = $('.js-cardListAjax');
const $pagePreloader = $('.page-preloader-bottom');
let pageNumber = 1;
let loading = false;

export function ajaxLoad({
	clear = true,
}){
	const url = $cardListAjax.data('ajax-url');
	const params = getTagIds();

	loading = true;

	$.ajax({
        url: url,
        data: {
            tags: params,
			page: pageNumber
        },
        type: 'GET',
        success: function(data) {
			if (data.success === true) {
				const html = $(data.data).html();

				pageNumber = data.current_page + 1;

				if (clear) {
					$('.js-mCustomScrollbar').mCustomScrollbar('scrollTo', 'top', { scrollInertia: 0 });
					$cardListAjax.html(html);
				} else {
					$cardListAjax.append(html);
				}

				if (data.current_page === data.last_page) {
					$pagePreloader.hide();
				} else {
					$pagePreloader.removeAttr('style');
				}

				cardTitleFs();
			}

			loading = false;
        }
    });
}

// $(window).on('scroll', () => {
// 	if ($(window).width() <= 1199 && $cardListAjax.length > 0) {
// 		const scrolled = $(window).scrollTop();
// 		const winH = $(window).height();
// 		const scrolledTotal = scrolled + winH;
// 		const docH = $(document).height();
//
// 		if (scrolledTotal === docH && !loading) {
// 			ajaxLoad({ clear: false });
// 		}
// 	}
// });
