import { aosInit } from './_aos';

$(document).ready(() => {
	setTimeout(() => {
		$('body').addClass('is-page-loaded');
		aosInit();
		setTimeout(() => {
			$('.prealoder').remove();
		}, 300);
	}, 1000);
});
