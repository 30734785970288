import mCustomScrollbar from 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js';
import { ajaxLoad } from './_cards-ajax';

const $round1 = $('.page-round__elem:first');
const $round2 = $('.page-round__elem.round_2');
const $round3 = $('.page-round__elem.round_3');

if ($(window).width() >= 1200) {
	initScrollbar();
}

function initScrollbar(){
	$('.js-mCustomScrollbar').mCustomScrollbar({
		scrollInertia: 800,
		mouseWheel: {
			scrollAmount: 300
		},
		callbacks: {
			whileScrolling: function(){
				const top1 = this.mcs.top * 0.05;
				const top2 = this.mcs.top * 0.07;
				const top3 = this.mcs.top * 0.03;

				if (this.mcs.top < -150) {
					$('.page-scroll-up').addClass('is_visible');
				} else {
					$('.page-scroll-up').removeClass('is_visible');
				}

				$round1.css('transform', `translate3d(0, ${top1}px, 0)`);
				$round2.css('transform', `translate3d(0, ${top2}px, 0)`);
				$round3.css('transform', `translate3d(0, ${top3}px, 0)`);
			},
			onTotalScroll: function(){
				$('body').removeClass('is-hide-header-footer');

				if (typeof get_next_items === 'function') {
					get_next_items();
				}

				// ajaxLoad({
				// 	clear: false
				// });
			},
			onTotalScrollBack: function(){
				$('body').removeClass('is-hide-header-footer');
			},
			onScrollStart: function(){
				$('body').addClass('is-hide-header-footer');
			},
	        onScroll: function(){
				$('[data-aos]').each((index, element) => {
					if ($(element).is(':mcsInSight')) {
				      $(element).addClass('aos-animate');
				    }
				});
	        }
	    }
	});
}

$('.page-scroll-up').on('click', () => {
	$('.js-mCustomScrollbar').mCustomScrollbar('scrollTo', 'top');
});

$(window).on('resize', () => {
	if ($(window).width() <= 1199) {
		$('.js-mCustomScrollbar').mCustomScrollbar('destroy');
	} else {
		initScrollbar();
	}
});

$(window).on('scroll', () => {
	if ($(window).width() <= 1199) {
		const scrolled = $(window).scrollTop();
		const winH = $(window).height();
		const scrolledTotal = scrolled + winH;
		const docH = $(document).height();

		if (scrolled === 0 || scrolledTotal === docH) {
			$('body').removeClass('is-hide-header-footer');
		} else {
			$('body').addClass('is-hide-header-footer');
		}
	}
});
