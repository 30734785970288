import { ajaxLoad } from './_cards-ajax';

$('.js-filterCardLink').on('click', (event) => {
	const $this = $(event.currentTarget);
	const id = $this.data('id');

	$this.toggleClass('is_active');

	const params = getTagIds();

	ajaxLoad({ params });
});


export function getTagIds(){
	const tags = [];

	$('.js-filterTags .is_active').each((index, element) => {
		tags.push($(element).data('id'));
	});

	return tags;
}
