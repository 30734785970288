function pagerTitleSize(){
	const title = $('.pager-prev__title, .pager-next__title');

	title.each((index, el) => {
		if ($(el).text().length > 35) {
			$(el).addClass('is_small_size');
		} else {
			$(el).removeClass('is_small_size');
		}
	});
}

pagerTitleSize();

$(window).on('load resize', () => {
	pagerTitleSize();
});
